body {
  background-color: black;
  color: white;
  text-align: center;

  /* background-image: url("https://www.transparenttextures.com/patterns/graphy-dark.png"); */
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1.heading{
  font-size: 4rem;
  margin-top: 4rem;
}